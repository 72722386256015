<template>
    <textarea class="textAreaUI" v-model="$store.state[bindTo.parent][bindTo.key]" cols="40" rows="1"></textarea>
    <!-- <input autocomplete="off" class="textInput" type="text" :value="$store.state[bindTo.parent][bindTo.key]" @input="updateData" /> -->
</template>
  
<script>
import { useStore } from "vuex";

export default {
    props: ["onComplete", "bindTo"],
    name: "TextInput",
    setup(props) {
        const store = useStore();

        // update VueX store data
        function updateData(event) {
            this.store.dispatch("updateState", {
                parent: props.bindTo.parent,
                key: props.bindTo.key,
                value: event.target.value,
            });

            // Trigger onComplete function (if present)
            if (props.onComplete != undefined) props.onComplete();
        }

        return { store, updateData };
    },
};
</script>
  
<style lang="scss">
@import "@/assets/styles/theming.scss";

.textAreaUI {
    font-size: 14px;
    line-height: 1;
    max-width: 80vw;
    width: 100%;
    height: 100px;
    border: 0px;
    border-radius: 10px;
    @include theme(border, textColor, 1px solid);
    @include theme(color, textColor);
    background: transparent;
    padding: 20px 20px 30px 20px;
    /* min-height: 0vw; */
    /* white-space: nowrap; */
}

textarea:focus,
.textAreaUI:focus {
    outline: none;
}

.textInput {
    text-align: left;
    /* font-size: max(10vw, 30px); */
    line-height: 1;
    width: 100%;
    border: 0px;
    /* border-bottom: 2px solid #e5e5e5; */
    /* min-height: 0vw; */
}

textarea:focus,
.textInput:focus {
    outline: none;
}
</style>