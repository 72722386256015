import { useStore } from "vuex";
import { watch } from "@vue/runtime-dom";

export default class Sketch {
    constructor(args) {
        // VueX
        this.store = useStore();

        // Initialize 
        this.init();
    }



    // INIT ---------------------------------------------------------------------------------------------
    init() {
        this.canvas = this.store.state.canvas.canvas;
        this.ctx = this.store.state.canvas.ctx;


        watch(
            () => this.store.state.helloWorld.dalle_response,
            () => {
                document.getElementById("generatingImages").style.display = 'none';

                // Push sources to state (will be picked up by Vue in the frontend)
                let generatedImageData = this.store.state.helloWorld.dalle_response;
                let generatedImages = [];

                for (let i = 0; i < generatedImageData.length; i++) {
                    generatedImages.push(generatedImageData[i].url);
                }
                this.store.dispatch("updateState", { parent: "helloWorld", key: "dalle_generatedImages", value: generatedImages });
            }
        );
    }



    // UPDATE ---------------------------------------------------------------------------------------------
    update() {
        this.dalle_prompt = this.store.state.helloWorld.dalle_prompt;
    }







    generateText() {
        this.store.dispatch("updateState", { parent: "helloWorld", key: "dalle_prompt", value: '' });
        this.store.dispatch("updateState", { parent: "helloWorld", key: "dalle_generatedImages", value: [] });

        document.getElementById("generatingPrompt").style.display = 'flex';

        this.generateTextIdea().then((generatedImage) => {
            this.generateImage();
        });
    }



    // -------------
    // -------------
    generateTextIdea() {
        return new Promise((resolve) => {
            console.log("Calling GPT3")
            let url = "https://api.openai.com/v1/completions";
            let bearer = 'Bearer ' + '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'
            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "model": "text-davinci-003",
                    "prompt": this.store.state.helloWorld.gpt_prompt,
                    "max_tokens": 200,
                    "temperature": 1,
                    "top_p": 1,
                    "n": 1,
                    "stream": false,
                    "logprobs": null,
                    "stop": [" Human:", " AI:"]
                })
            }).then(response => {
                return response.json()
            }).then(data => {
                // console.log(data)
                // console.log(typeof data)
                // console.log(Object.keys(data))
                // console.log(data['choices'][0].text)

                this.store.dispatch("updateState", { parent: "helloWorld", key: "dalle_prompt", value: data['choices'][0].text });
                document.getElementById("generatingPrompt").style.display = 'none';
                resolve(data['choices'][0].text);
            })
                .catch(error => {
                    console.log('Something bad happened ' + error)
                });
        });
    }



    // -------------
    // -------------
    generateImage() {
        document.getElementById("generatingImages").style.display = 'flex';

        console.log("Calling DALL E")
        let url = "https://api.openai.com/v1/images/generations";
        let bearer = 'Bearer ' + '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'
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "prompt": this.store.state.helloWorld.dalle_prompt,
                "n": parseFloat(this.store.state.helloWorld.imagesToGenerate),
                "size": parseFloat(this.store.state.helloWorld.imageResolution) + "x" + parseFloat(this.store.state.helloWorld.imageResolution)
            })
        }).then(response => {
            return response.json()
        }).then(data => {
            // console.log(data)
            // console.log(typeof data)
            // console.log(Object.keys(data))
            // console.log(data['data'][0].url)

            // console.log(data['data'])
            // console.log(data['data'].length)
            // this.store.dispatch("updateState", { parent: "helloWorld", key: "dalle_response", value: data['data'][0].url });
            this.store.dispatch("updateState", { parent: "helloWorld", key: "dalle_response", value: data['data'] });
        })
            .catch(error => {
                console.log('Something bad happened ' + error)
            });
    }





}


