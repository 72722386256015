<template>
    <div id="helloWorld">
        <div id="UI">
            <div id="UI_content">
                <div id="UI_inputArea">
                    <TextInput :focus="true" :onEnterKey="handleNewPrompt" :bindTo="{ parent: 'helloWorld', key: 'gpt_prompt' }" />
                    <Button title="OK" @click="handleNewPrompt" />
                </div>
                <div id="UI_optionArea">
                    <div class="UI_option" style="margin-right: 20px;">
                        <div class="UI_label">Amount of images to generate</div>
                        <Dropdown :selected="$store.state.helloWorld.imagesToGenerate" :bindTo="{ parent: 'helloWorld', key: 'imagesToGenerate' }" :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" />
                    </div>
                    <div class="UI_option">
                        <div class="UI_label">Resolution of images</div>
                        <Dropdown :selected="$store.state.helloWorld.imageResolution" :bindTo="{ parent: 'helloWorld', key: 'imageResolution' }" :options="{ '256x256 pixels': 256, '512x512 pixels': 512, '1024x1024 pixels': 1024, '2048x2048 pixels': 2048 }" />
                    </div>
                </div>

                <div id="generatingPrompt">
                    <div class="spinner"></div>
                    <span>Generating DALL-E2 prompt via GPT-3.</span>
                </div>
                <div id="UI_gptResult">
                    <div id="UI_gptResultArea">
                        <div v-show="$store.state.helloWorld.dalle_prompt != ''">Dynamic image prompt generated by GPT3</div>
                        {{ $store.state.helloWorld.dalle_prompt }}
                    </div>
                </div>

            </div>
        </div>
        <div id="resultsContainer">
            <div id="generatingImages">
                <div class="spinner"></div>
                <span>Generating images based on GPT-3 prompt.</span>
            </div>
            <span v-for="( image, imageIndex ) in  $store.state.helloWorld.dalle_generatedImages ">
                <img :src="image" class="dalleImage" :key="imageIndex" />
            </span>
        </div>
    </div>
</template>


<script>
// Vue
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { onMounted, onUnmounted } from "@vue/runtime-dom";

// UI Components
import Input from "@/components/UI/components/input.vue";
import TextInput from "@/components/UI/components/textInput.vue";
import Button from "@/components/UI/components/button.vue";
import Dropdown from "@/components/UI/components/dropdown.vue";

// CanvasManager
let canvasManager = ref(null); // reference to the manager
export { canvasManager }; // export manager-reference to expose it to other components

// Specific animation object
import Sketch from './HelloWorld.js';

export default {
    name: "Simple AI text generation test",
    components: {
        Input,
        TextInput,
        Button,
        Dropdown,
    },

    setup() {
        const store = useStore();
        let sketch = undefined;

        onMounted(() => {
            sketch = new Sketch({});
        });

        onUnmounted(() => {
        });



        function handleNewPrompt() {
            sketch.generateText();
        }

        return { handleNewPrompt };
    }
}

</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";

#generatingImages {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    span {
        display: block;
        margin-left: 10px;
    }

    display: none;
}

#helloWorld {
    width: 100vw;
    height: 100vh;
    display: flex;
    font-size: 14px;

    #resultsContainer,
    #sketchCanvas {
        width: 50vw;
        height: 100vh;
    }

    #resultsContainer {
        display: block;

        img {
            width: calc(50% - 4px);
            height: auto;
            border: 2px solid #ecf2f7;

            &:hover {
                border: 2px solid #000;
            }
        }
    }

    #UI_gptResult {
        margin-top: 50px;
    }

    #UI_gptResultArea {
        div {
            font-weight: bold;
            margin-bottom: 20px;
        }
    }

    #UI {
        -webkit-box-shadow: -3px 0px 46px 9px rgba(0, 0, 0, 0.05);
        box-shadow: -3px 0px 46px 9px rgba(0, 0, 0, 0.05);
        border-radius: 50px;
        // max-width: 500px;
        width: 50vw;
        height: 100vh;
        margin-left: auto;

        #UI_content {
            margin-top: 60px;
            padding: 30px;

            #UI_inputArea {
                display: flex;
            }
        }

        #UI_content {
            // margin-top: 0;
            // height: 100vh;
            // background: #fff;

            h4 {
                width: 100%;
                border-bottom: 1px solid #000;
                margin-bottom: 30px;
            }

            img {
                max-height: 200px;
            }
        }


    }
}



#AI_response {
    position: absolute;
    top: 0;
    left: 0;
}

#AI_responseContent {
    width: 90vw;
    height: 80vh;
    margin: 10vh 0 0 5vw;

    font-size: 18px;
    @include theme(color, textColor);
}

#AI_prompt {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

#AI_prompt_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    // input {
    //     height: 30px;
    //     width: 400px !important;
    // }

    button {
        margin-left: 20px;
        height: 50px;
    }
}

.section {
    .section_number {
        font-size: 70px;
        margin-right: 30px;
    }
}

#generatingPrompt {
    margin-top: 30px;
    display: none;
    align-items: center;

    span {
        display: block;
        margin-left: 10px;
    }
}


.spinner {
    width: 56px;
    height: 56px;
    display: grid;
    animation: spinner-plncf9 4s infinite;
}

.spinner::before,
.spinner::after {
    content: "";
    grid-area: 1/1;
    border: 9px solid;
    border-radius: 50%;
    border-color: #000000 #000000 #0000 #0000;
    mix-blend-mode: darken;
    animation: spinner-plncf9 1s infinite linear;
}

.spinner::after {
    border-color: #0000 #0000 #dbdcef #dbdcef;
    animation-direction: reverse;
}

@keyframes spinner-plncf9 {
    100% {
        transform: rotate(1turn);
    }
}

#UI_optionArea {
    margin-top: 20px;
    display: flex;
}

.UI_option {
    width: 100%;
}
</style>
