<template>
  <input :placeholder="placeHolder" :maxlength="maxLength" ref="inputComponent" v-on:keyup.enter="enterFunction" autocomplete="off" class="UI_input" :value="$store.state[bindTo.parent][bindTo.key]" @input="updateData" />
  <label>{{ label }}</label>
</template>

<script>
import { watch, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  props: ["onComplete", "bindTo", "label", "onEnterKey", "focus", "maxLength", "placeHolder"],
  name: "Input",
  setup(props) {
    const inputComponent = ref(null);
    const store = useStore();

    onMounted(() => {
      if (props.focus) {
        inputComponent.value.focus();
      }
    })

    // update VueX store data
    function updateData(event) {
      this.store.dispatch("updateState", {
        parent: props.bindTo.parent,
        key: props.bindTo.key,
        value: event.target.value,
      });

      // Trigger onComplete function (if present)
      if (props.onComplete != undefined) props.onComplete();
    }

    function enterFunction() {
      if (props.onEnterKey != undefined) props.onEnterKey();
    }

    return { store, updateData, enterFunction, inputComponent };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/theming.scss";

.UI_input {
  width: auto !important;
  padding: 10px;
  @include theme(border, textColor, 1px solid);
  @include theme(color, textColor);
  background: transparent;
  font-size: 14px;
  line-height: 1;
  width: 100% !important;
  border-radius: 10px;
}

.UI_input:focus {
  outline: none;
}
</style>